<template>
  <div class="access-editor">
    <access-policy
      :value="accessPolicy"
      :access-types="accessTypes"
      suppress-confirm
      :loading="loading"
      @update:value="updateAccessPolicy"
    >
      <template #actions-right>
        <div class="_right">
          <m-btn
            class="_btn"
            hide-border
            small
            @click="$emit('close')"
          >
            {{ $t('general.cancel') }}
          </m-btn>
          <m-btn
            color="primary"
            class="_btn"
            small
            :loading="loading"
            @click="save"
          >
            {{ $t('general.save') }}
          </m-btn>
        </div>
      </template>
    </access-policy>
  </div>
</template>

<script>
import AccessPolicy from '@/components/access-policy/AccessPolicy.vue';
import useConfirmDialog from '@/composables/confirm-dialog';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useSnackBar from '@/composables/snackbar';
import { ACCESS_POLICY_TYPE_MIXED } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import {
  accessPolicyCommon,
  cmpAccessRight,
  composedAccountAccess,
  getAccessTypeOfUser,
} from '@/lib/access-policy';
import { accessPolicyType } from 'shared/constants.json';
import { applyPatch, composedAccessPolicy } from '@/lib/access-policy-linking';
import { copy } from 'shared/lib/copy';
import { isNullOrUndefined } from 'shared/lib/object/object';

export default {
  name: 'AccessEditor',
  props: {
    items: {
      type: Array,
      required: true,
    },
    updateEntitiesFn: {
      type: Function,
      default: () => new Promise((resolve) => { resolve(); }),
    },
    successMessage: {
      type: String,
      default: '',
    },
    accessTypes: {
      type: Array,
      default: () => [accessPolicyType.read, accessPolicyType.write, accessPolicyType.full],
    },
  },
  emits: ['close', 'edited'],
  components: { AccessPolicy },
  setup() {
    const snackbar = useSnackBar();
    const confirmDialog = useConfirmDialog();
    const { loggedInUser } = useLoggedInUser();
    return { snackbar, confirmDialog, loggedInUser };
  },
  data() {
    return { accessPolicy: null, loading: false };
  },
  methods: {
    updateAccessPolicy(val) {
      this.accessPolicy = val;
    },
    confirmSomeDisconnect(next) {
      return this.confirmDialog.confirm({
        title: this.$t('accessEditor.someDisconnectingLink.title'),
        message: { text: this.$t('accessEditor.someDisconnectingLink.message') },
        okText: this.$t('accessEditor.someDisconnectingLink.cta'),
        hideOnSubmit: false,
        onOk() {
          EventBus.$emit('hide-confirm');
          next();
        },
      });
    },
    confirmSomeLoseAccess(next) {
      return this.confirmDialog.confirm({
        title: this.$t('accessEditor.someLoseAccess.title'),
        message: { text: this.$t('accessEditor.someLoseAccess.message') },
        okText: this.$t('general.change'),
        hideOnSubmit: false,
        onOk() {
          EventBus.$emit('hide-confirm');
          next();
        },
      });
    },
    confirmSomeLoseFull(next) {
      return this.confirmDialog.confirm({
        title: this.$t('accessEditor.someLoseFullAccess.title'),
        message: { text: this.$t('accessEditor.someLoseFullAccess.message') },
        okText: this.$t('general.change'),
        hideOnSubmit: false,
        onOk() {
          EventBus.$emit('hide-confirm');
          next();
        },
      });
    },
    save() {
      const entities = this.items.map((item) => {
        const accessPolicy = copy(item.accessPolicy);

        const iComposedAccountAccess = composedAccountAccess(accessPolicy).accountAccess;
        if (this.accessPolicy.accountAccess !== ACCESS_POLICY_TYPE_MIXED && this.accessPolicy.accountAccess !== iComposedAccountAccess) {
          accessPolicy.accountAccess = this.accessPolicy.accountAccess;

          if (cmpAccessRight(this.accessPolicy.accountAccess, iComposedAccountAccess) < 0) {
            //  If getting more restrictive, evaluate links bc we need to verify if each link gets replaced
            for (let i = 0; i < accessPolicy.links.length; i++) {
              const link = accessPolicy.links[i];
              const linkAP = composedAccessPolicy(link);
              if (cmpAccessRight(this.accessPolicy.accountAccess, linkAP.accountAccess) < 0) {
                const modifiedLink = applyPatch(link, { ...linkAP, accountAccess: this.accessPolicy.accountAccess });
                accessPolicy.links[i] = { ...accessPolicy.links[i], deactivatedAt: modifiedLink.deactivatedAt, patch: modifiedLink.patch };
              }
            }
          }
        }

        accessPolicy.scopes = accessPolicy.scopes.map((aps) => {
          const match = this.accessPolicy.scopes.find((s) => s.scope.treeHash === aps.scope.treeHash);
          if (match.accessType !== ACCESS_POLICY_TYPE_MIXED && match.accessType !== aps.accessType) {
            aps.accessType = match.accessType;
            if (aps.accessType === accessPolicyType.disabled) {
              aps.deletedAt = match.deletedAt;
            }
          }
          return aps;
        });

        const missingScopes = this.accessPolicy.scopes.filter((aps) => item.accessPolicy.scopes.find((patchAPS) => patchAPS.scope.treeHash === aps.scope.treeHash) === undefined);
        accessPolicy.scopes.push(...missingScopes);

        return {
          uid: item.uid,
          accessPolicy,
        };
      });

      const save = () => {
        this.loading = true;
        this.updateEntitiesFn(entities).then(() => {
          this.snackbar.success(this.successMessage);
          this.$emit('edited');
          this.$emit('close');
        }).catch(() => {
          this.snackbar.error();
        }).finally(async () => {
          this.loading = false;
        });
      };

      const confirmSomeLoseAccess = (next) => {
        const someLoseAccess = this.items.some((oldValue) => {
          const newValue = entities.find((ap) => ap.uid === oldValue.uid);
          return getAccessTypeOfUser({ accessPolicy: newValue.accessPolicy, creator: oldValue.creator }, this.loggedInUser).accessRight === accessPolicyType.disabled;
        });
        if (someLoseAccess) {
          this.confirmSomeLoseAccess(next);
          return;
        }
        next();
      };
      const confirmSomeLoseFull = (next) => {
        const someLoseFull = this.items.some((oldValue) => {
          const newValue = entities.find((ap) => ap.uid === oldValue.uid);
          return getAccessTypeOfUser({ accessPolicy: newValue.accessPolicy, creator: oldValue.creator }, this.loggedInUser).accessRight !== accessPolicyType.full;
        });
        if (someLoseFull) {
          this.confirmSomeLoseFull(next);
          return;
        }
        next();
      };
      const confirmSomeDisconnect = (next) => {
        const mustDisconnect = (newValue, oldValue) => oldValue.links.some((oldLink) => {
          const newLink = newValue.links.find((link) => link.uid === oldLink.uid);
          return oldLink.deactivatedAt === null && !isNullOrUndefined(newLink.deactivatedAt);
        });
        const someDisconnect = this.items.some((oldValue) => {
          const newValue = entities.find((item) => item.uid === oldValue.uid);
          return mustDisconnect(newValue.accessPolicy, oldValue.accessPolicy);
        });
        if (someDisconnect) {
          this.confirmSomeDisconnect(next);
          return;
        }
        next();
      };
      confirmSomeLoseAccess(() => confirmSomeLoseFull(() => confirmSomeDisconnect(() => save())));
    },
  },
  created() {
    this.accessPolicy = accessPolicyCommon(this.items.map((item) => item.accessPolicy));
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .access-editor {
    ._actions {
      display: flex;
      justify-content: space-between;

      ._left {
        color: $font-color-secondary;
      }

      ._right {
        display: flex;
        gap: .6rem;
      }
    }
  }
</style>
