// feature flags
import {
  accessGroup as accessGroupConfig,
  accountSettings as accountSettingsConfig,
} from 'shared/api/query/configs.json';
import { accessGroupFlag, dataSourceType, featureFlag, moduleFlag } from 'shared/constants.json';
import { experimentFlag } from 'shared/experiments.json';
import { featureFlagToPlanFeature, getPlan } from 'shared/lib/plans';

export const AND = 'and';
export const OR = 'or';

const flagToAccountSettingEdgesMap = {
  [moduleFlag.goals]: accountSettingsConfig.edges.usesGoals,
  [moduleFlag.updates]: accountSettingsConfig.edges.usesUpdates,
  [moduleFlag.plannings]: accountSettingsConfig.edges.usesPlannings,
  [moduleFlag.surveys]: accountSettingsConfig.edges.usesSurveys,
  [moduleFlag.reviews]: accountSettingsConfig.edges.usesReviews,
  [moduleFlag.instantFeedbacks]: accountSettingsConfig.edges.usesInstantFeedback,

  [featureFlag.slack]: accountSettingsConfig.edges.usesSlack,
  [featureFlag.microsoftBot]: accountSettingsConfig.edges.usesMicrosoftBot,
  [featureFlag.saml]: accountSettingsConfig.edges.usesSaml,
  [featureFlag.userProvisioning]: accountSettingsConfig.edges.usesUserProvisioning,
  [featureFlag.healthDashboard]: accountSettingsConfig.edges.usesHealthDashboard,
  [featureFlag.publishedViews]: accountSettingsConfig.edges.usesPublishedViews,
  [featureFlag.restrictPasswordLogin]: accountSettingsConfig.edges.restrictPasswordLogin,
  [featureFlag.companyInfo]: accountSettingsConfig.edges.usesCompanyInfo,
  [featureFlag.weightedGoals]: accountSettingsConfig.edges.usesWeightedGoals,
  [featureFlag.multiGoalAlignment]: accountSettingsConfig.edges.usesMultiGoalAlignment,
  [featureFlag.advancedOkrRules]: accountSettingsConfig.edges.usesAdvancedOKRRules,
  [featureFlag.goalTree]: accountSettingsConfig.edges.usesGoalTree,
  [featureFlag.fileUpload]: accountSettingsConfig.edges.usesFileUpload,
  [featureFlag.usesBillingPortal]: accountSettingsConfig.edges.usesBillingPortal,
  [featureFlag.usesMultiMsAccounts]: accountSettingsConfig.edges.usesMultiMsAccounts,

  [dataSourceType.sheets]: accountSettingsConfig.edges.usesSheets,
  [dataSourceType.excel]: accountSettingsConfig.edges.usesExcel,
  [dataSourceType.jira]: accountSettingsConfig.edges.usesJira,
  [dataSourceType.asana]: accountSettingsConfig.edges.usesAsana,
  [dataSourceType.hubspot]: accountSettingsConfig.edges.usesHubspot,
  [dataSourceType.salesforce]: accountSettingsConfig.edges.usesSalesforce,
  [dataSourceType.msPowerBi]: accountSettingsConfig.edges.usesMSPowerBI,
  [dataSourceType.msPlanner]: accountSettingsConfig.edges.usesMSPlanner,
};

export const accountSettingsEdgeByFlag = (flag) => {
  const result = flagToAccountSettingEdgesMap[flag];
  if (typeof result === 'undefined') {
    throw new Error('flag not present');
  }
  return result;
};

export const getFlagsOfAccount = (account) => {
  const flags = [];
  const accountSettings = account.accountSettings;

  const accountFlags = Object.keys(flagToAccountSettingEdgesMap);
  for (let i = 0; i < accountFlags.length; i++) {
    const f = accountFlags[i];
    if (accountSettings[flagToAccountSettingEdgesMap[f]] === true) {
      flags.push(f);
    }
  }

  return flags;
};

export const canUseFeature = (neededFlags, accountFlags) => neededFlags.every((element) => accountFlags.indexOf(element) > -1);

const accountInExperiment = (flags, account) => {
  const accounts = [];
  flags.forEach((flag) => {
    if (experimentFlag.all.includes(flag)) {
      switch (flag) {
        case experimentFlag.customReports:
          accounts.push(260015);
          break;
        case experimentFlag.accessRights2:
          accounts.push(260015, 85308090, 94415474);
          break;
        default:
          break;
      }
    }
  });
  return accounts.includes(account.uid);
};

export const accountHasFeature = (neededFlags, account) => canUseFeature(neededFlags, getFlagsOfAccount(account)) || accountInExperiment(neededFlags, account);

export const userHasRight = (needsFlags, accessGroups, operator = AND) => {
  const edges = accessGroupConfig.edges;
  const available = accessGroups.reduce((res, cur) => {
    if (cur[edges.createGoalAccess] === true) {
      res[accessGroupFlag.createGoal] = null;
    }
    if (cur[edges.goalSuperAccess] === true) {
      res[accessGroupFlag.goalSuperAccess] = null;
    }
    if (cur[edges.updateAdminAccess] === true) {
      res[accessGroupFlag.updateAdminAccess] = null;
    }
    if (cur[edges.accountWriteAccess] === true) {
      res[accessGroupFlag.accountWriteAccess] = null;
    }
    if (cur[edges.goalDashboardViewAccess] === true) {
      res[accessGroupFlag.goalDashboardView] = null;
    }
    if (cur[edges.publicSavedViewAccess] === true) {
      res[accessGroupFlag.publicSavedViews] = null;
    }
    if (cur[edges.instantFeedbackTemplateAccess] === true) {
      res[accessGroupFlag.instantFeedbackTemplateAccess] = null;
    }
    if (cur[edges.foreignEntityReferenceAccess] === true) {
      res[accessGroupFlag.foreignEntityReference] = null;
    }
    if (cur[edges.goalCycleWriteAccess] === true) {
      res[accessGroupFlag.goalCycleWriteAccess] = null;
    }
    if (cur[edges.userWriteAccess] === true) {
      res[accessGroupFlag.userWriteAccess] = null;
    }
    if (cur[edges.propertyWriteAccess] === true) {
      res[accessGroupFlag.propertyWriteAccess] = null;
    }
    if (cur[edges.formWriteAccess] === true) {
      res[accessGroupFlag.formWriteAccess] = null;
    }
    if (cur[edges.formTemplateWriteAccess] === true) {
      res[accessGroupFlag.formTemplateWriteAccess] = null;
    }
    if (cur[edges.accessGroupWriteAccess] === true) {
      res[accessGroupFlag.accessGroupWriteAccess] = null;
    }
    if (cur[edges.spaceWriteAccess] === true) {
      res[accessGroupFlag.spaceWriteAccess] = null;
    }
    if (cur[edges.createDashboardAccess] === true) {
      res[accessGroupFlag.createDashboard] = null;
    }
    if (cur[edges.dashboardSuperAccess] === true) {
      res[accessGroupFlag.dashboardSuperAccess] = null;
    }

    return res;
  }, {});

  if (operator === AND) {
    return needsFlags.every((nf) => typeof available[nf] !== 'undefined');
  }
  return needsFlags.some((nf) => typeof available[nf] !== 'undefined');
};

export const accountCanActivateFeature = (flag, planId) => {
  const plan = getPlan(planId);
  const result = featureFlagToPlanFeature(flag, plan);
  if (result === undefined) {
    return false;
  }
  return result;
};
