import useAccess from '@/composables/access/access';
import useConfirmDialog from '@/composables/confirm-dialog';
import useGoalAccessPolicyLinks from '@/composables/goal/access-policy-links';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import useUsers from '@/composables/user/users';
import { accessPolicyType, propertyType, userScopeOperator, userScopeType } from 'shared/constants.json';
import { cmpAccessRight, getAccessTypeOfUser } from '@/lib/access-policy';
import { difference } from 'shared/lib/array/array';
import { experimentFlag } from 'shared/experiments.json';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { stripPropertyValue } from '@/lib/stripper';
import { useI18n } from 'vue-i18n';

export default function useSetProperties({ goalsSvc }) {
  const confirmDialog = useConfirmDialog();
  const i18n = useI18n();
  const { calculateAccessPolicyWithLinks } = useGoalAccessPolicyLinks();
  const usersSvc = useUsers();

  const getEdgeName = (property) => {
    if (property.edgeName !== undefined) {
      return property.edgeName;
    }
    if (property.type !== undefined) {
      return property.type;
    }
    throw new Error('edge name of property not found');
  };

  const transformProperties = ({ properties, value }) => {
    delete value.uid;
    return properties.map((pv) => {
      if (pv.property.uid !== value.property.uid) {
        return pv;
      }
      return { ...pv, ...value };
    });
  };

  const { accountHasFeature } = useAccess();
  const { loggedInUser } = useLoggedInUser();
  const { loggedInUserAccount } = useLoggedInUserAccount();
  const { spaceProperty } = useProperties();
  const updateProperty = (value, property, goals) => {
    const edge = getEdgeName(property);
    if ([goalConfig.edges.description, goalConfig.edges.goalCycle].includes(edge)) {
      return goalsSvc.updateMultiple(goals.map((goal) => ({ uid: goal.uid, [edge]: value })));
    }
    if (edge === goalConfig.edges.parents) {
      return goalsSvc.changeParents({ goals, parents: value.map((uid) => ({ uid })) }).then(() => goals);
    }

    const goalsWithDowngradedAccess = {};
    const goalsUsersWithoutAccess = {};
    const goalsToUpdate = goals.map((goal) => {
      const properties = transformProperties({ properties: goal.properties, value }).map(stripPropertyValue);
      const goalToUpdate = { uid: goal.uid, properties };
      if (accountHasFeature([experimentFlag.accessRights2])) {
        if (property.uid === spaceProperty.value.uid) {
          goalToUpdate.accessPolicy = calculateAccessPolicyWithLinks({ accessPolicy: goal.accessPolicy, properties });
          // restore apLinks bf calling getAccessTypeOfUser
          const accessPolicyToCheck = {
            ...goalToUpdate.accessPolicy,
            links: goalToUpdate.accessPolicy.links.map((l) => {
              if (l.uid !== undefined && l.link !== undefined) {
                return { ...l, link: goal.accessPolicy.links.find((ol) => ol.uid === l.uid).link };
              }
              return l;
            }).filter((l) => l.deletedAt === undefined),
          };
          if (cmpAccessRight(getAccessTypeOfUser({ ...goal, accessPolicy: accessPolicyToCheck }, loggedInUser.value).accessRight, goal.accessRight) < 0) {
            goalsWithDowngradedAccess[goal.uid] = { accessPolicy: goalToUpdate.accessPolicy };
          }
        }
        if (edge === propertyType.user) {
          const oldUsersIds = goal.properties.find((pv) => pv.property.uid === value.property.uid).users.map((u) => u.uid);
          const newUsersIds = value.users.map((u) => u.uid);
          const addedUsers = usersSvc.selectMultiple(difference(newUsersIds, oldUsersIds));
          const usersWithoutAccess = addedUsers.filter((u) => getAccessTypeOfUser(goal, u).accessRight === accessPolicyType.disabled);
          if (usersWithoutAccess.length > 0) {
            const scopes = [...goal.accessPolicy.scopes];
            scopes.push(...usersWithoutAccess.map((u) => ({
              accessType: accessPolicyType.comment,
              scope: { account: { uid: loggedInUserAccount.value.uid }, op: userScopeOperator.or, children: [{ op: userScopeOperator.and, children: [{ op: userScopeOperator.and, scope: { type: userScopeType.staticUsers, staticUsers: [u] } }] }] },
              scopeUsers: [u],
            })));
            goalsUsersWithoutAccess[goal.uid] = { accessPolicy: { uid: goal.accessPolicy.uid, scopes }, accessRight: goal.accessRight };
          }
        }
      }
      return goalToUpdate;
    });

    if (property.uid === spaceProperty.value.uid && Object.keys(goalsWithDowngradedAccess).length > 0) {
      return new Promise((resolve) => {
        confirmDialog.confirm({
          title: i18n.t('accessPolicy.downgradedAccessBySpace.title', { entityType: i18n.t('accessPolicy.entityType.goal', Object.keys(goalsWithDowngradedAccess).length) }),
          message: { text: i18n.t('accessPolicy.downgradedAccessBySpace.message', { entityType: i18n.t('accessPolicy.entityType.goal', Object.keys(goalsWithDowngradedAccess).length) }) },
          okText: i18n.t('general.change'),
          onOk() {
            resolve(goalsSvc.updateMultiple(goalsToUpdate));
          },
        });
      });
    }

    if (edge === propertyType.user && Object.keys(goalsUsersWithoutAccess).length > 0) {
      if (Object.values(goalsUsersWithoutAccess).some((goal) => goal.accessRight === accessPolicyType.full)) {
        return new Promise((resolve) => {
          confirmDialog.confirm({
            title: i18n.t('accessPolicy.grantAccessByUser.title'),
            message: { text: i18n.t('accessPolicy.grantAccessByUser.message', { entityType: i18n.t('accessPolicy.entityType.goal', Object.keys(goalsUsersWithoutAccess).length) }), type: 'info' },
            okText: i18n.t('general.confirm'),
            okType: 'primary',
            cancelText: i18n.t('general.skip'),
            onOk() {
              resolve(goalsSvc.updateMultiple(goalsToUpdate.map((goal) => {
                if (goalsUsersWithoutAccess[goal.uid].accessRight === accessPolicyType.full) {
                  return { ...goal, accessPolicy: goalsUsersWithoutAccess[goal.uid].accessPolicy };
                }
                return goal;
              })));
            },
            onCancel() {
              resolve(goalsSvc.updateMultiple(goalsToUpdate));
            },
          });
        });
      }
      return new Promise((resolve) => {
        confirmDialog.confirm({
          title: i18n.t('accessPolicy.warnGrantAccessByUser.title'),
          message: { text: i18n.t('accessPolicy.warnGrantAccessByUser.message', { entityType: i18n.t('accessPolicy.entityType.goal', Object.keys(goalsUsersWithoutAccess).length) }) },
          okText: i18n.t('general.okay'),
          okType: 'warning',
          hideCancel: true,
          onOk() {
            resolve(goalsSvc.updateMultiple(goalsToUpdate));
          },
        });
      });
    }

    return goalsSvc.updateMultiple(goalsToUpdate);
  };

  return { updateProperty };
}
