import useProperties from '@/composables/property/property';
import useSpaces from '@/composables/space/spaces';

export default function useGoalAccessPolicyLinks() {
  const spacesSvc = useSpaces();
  const { spaceProperty } = useProperties();

  const searchLinkableAccessPolicies = ({ properties }) => {
    const spacePV = properties.find((pv) => pv.property.uid === spaceProperty.value.uid);
    if (spacePV === undefined) {
      return [];
    }
    const spaces = spacesSvc.selectMultiple(spacePV.spaces.map((s) => s.uid));
    return spaces.map((space) => space.permissions);
  };

  const calculateAccessPolicyWithLinks = ({ accessPolicy, properties }) => {
    const linkableAPs = searchLinkableAccessPolicies({ properties });
    const existingAPLinks = accessPolicy.links?.filter((apLink) => linkableAPs.find((ap) => ap.uid === apLink.link.uid) !== undefined).map((apLink) => ({ ...apLink, link: { uid: apLink.link.uid } })) ?? [];
    const deletedAPLinks = accessPolicy.links?.filter((apLink) => linkableAPs.find((ap) => ap.uid === apLink.link.uid) === undefined).map((apLink) => ({ uid: apLink.uid, deletedAt: new Date() })) ?? [];
    const missingAPLinks = linkableAPs.filter((ap) => accessPolicy.links?.find((apLink) => apLink.link.uid === ap.uid) === undefined).map((ap) => ({ link: ap }));
    return { ...accessPolicy, links: [...existingAPLinks, ...deletedAPLinks, ...missingAPLinks] };
  };

  return { searchLinkableAccessPolicies, calculateAccessPolicyWithLinks };
}
