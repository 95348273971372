<template>
  <div
    :class="['updates-explorer']"
  >
    <div class="_header">
      <updates-list-header
        :style="{ paddingBottom: '.6rem' }"
        :slots="headerSlots"
        :show-create-view="showCreateView"
        :read-only="readOnly"
        :filter-props="defaultProps"
        :show-views-selector="showViewsSelector"
        :data-loading="updatesLoading"
        @create="openCreateModal"
      />
      <m-divider none />
    </div>
    <div :class="['_list', bordered ? '-bordered' : '']">
      <m-spinner
        v-if="firstLoading && filteredUpdates.length === 0"
        class="_spinner"
      />
      <div
        v-else
        :class="['_inner', isFeed ? '-feed' : '-list']"
      >
        <div
          v-if="filteredUpdates.length === 0"
          class="_empty"
        >
          {{ $t('general.noEntries') }}
        </div>
        <m-endless-scroll-list
          v-else
          :key="`${listLoading}`"
          @visible="loadMore"
        >
          <template v-if="isFeed">
            <div
              v-for="(group, key) in groupedUpdates"
              :key="key"
              class="_sticky-container"
              :style="{ position: 'relative' }"
            >
              <template v-if="!isCurrentWeek(key)">
                <update-divider
                  :date="key"
                  class="_date-indicator"
                />
                <div class="_divider">
                  <m-divider />
                </div>
              </template>
              <update-feed-item
                v-for="item in group"
                :key="item.uid"
                :update="item"
                :props="viewProps"
                :wrap-titles="currentView.params.wrapTitles"
                class="_item"
                padding
              />
            </div>
          </template>
          <template v-else>
            <template
              v-for="item in filteredUpdates"
              :key="item.uid"
            >
              <update-list-item
                :update="item"
                :props="viewProps"
              />
              <m-divider
                v-if="bordered || $store.state.breakpoint.smAndDown"
                :key="`divider_${item.uid}`"
                small
              />
            </template>
          </template>
        </m-endless-scroll-list>
        <div
          class="_add"
        >
          <m-btn
            v-if="!listLoading"
            hide-border
            light
            full-width
            block
            :style="{ justifyContent: 'flex-start' }"
            icon="plus"
            @click="openCreateModal"
          >
            {{ $t('general.add') }}
          </m-btn>
        </div>
      </div>
    </div>
  </div>
  <m-dialog
    :value="showCreatePage"
    :max-width="$modalSizes.xl"
    hide-footer
    no-padding
    keep-height
    hide-header
    top="7rem"
    @close="closeCreateModal"
  >
    <update-editor
      :templates-loading="anyTemplatesLoading"
      :modal="true"
      @create="handleCreated"
      @cancel="closeCreateModal"
      @is-dirty="updateDirty = true"
    />
  </m-dialog>
  <m-dialog
    v-model:value="showSingleUpdate"
    :max-width="$modalSizes.xl"
    hide-footer
    no-padding
    keep-height
    hide-header
    top="7rem"
  >
    <update-editor
      :update="singleUpdate"
      disabled
      :modal="true"
      @saved="updateDirty = false"
    />
  </m-dialog>
  <checkin-onboarding-modal
    v-if="showOnboardingModal"
  />
  <product-tour
    v-if="showTour"
    :steps="productTourSteps"
    @finish="finishProductTour"
    @close="closeTour"
  />
  <after-checkin-tour-modal
    :value="showAfterProductTourModal"
    @close="showAfterProductTourModal = false"
  />
</template>

<script>
import AfterCheckinTourModal from '@/components/onboarding/AfterCheckinTourModal.vue';
import CheckinOnboardingModal from '@/components/onboarding/CheckinOnboardingModal.vue';
import ProductTour from '@/components/onboarding/ProductTour.vue';
import UpdateDivider from '@/components/update-feed/UpdateDivider.vue';
import UpdateEditor from '@/components/updates/UpdateEditor.vue';
import UpdateFeedItem from '@/components/updates/UpdateFeedItem.vue';
import UpdateListItem from '@/components/updates/UpdateListItem.vue';
import UpdatesListHeader from '@/components/updates/UpdatesListHeader.vue';
import updateDivider from '@/mixins/update-divider';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useDebounce from '@/composables/debounce';
import useFirstLoading from '@/composables/first-loading';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useOnboardingMarker from '@/composables/onboarding/onboarding-marker';
import useSnackBar from '@/composables/snackbar';
import useUpdateDefaultProps from '@/composables/saved-views/update-default-props';
import useUpdateProperty from '@/composables/property/update-property';
import useUpdateSchedules from '@/composables/update-schedules/update-schedules';
import useUpdateTemplates from '@/composables/update-templates/update-templates';
import useUpdates from '@/composables/updates/updates';
import useUpdatesSorting from '@/composables/updates/sort';
import useUsers from '@/composables/user/users';
import useViewParamsProps from '@/composables/view-params/view-params-props';
import { $confirm } from '@/lib/confirm';
import { AND, combine } from 'shared/api/query/filter';
import { DateTime } from 'luxon';
import { SLOTS, VIEWS_SERVICE } from '@/lib/constants';
import { UpdatesFilterHandler } from '@/lib/filter/updates/handler';
import { computed, inject, ref } from 'vue';
import { isInFilter } from '@/lib/filter/base-frontend-filter/handler';
import { logCatch } from '@/lib/logger/logger';
import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';
import { onboardingSteps, routeName, updateType, viewType } from 'shared/constants.json';
import { useI18n } from 'vue-i18n';

export default {
  name: 'UpdatesExplorer',
  props: {
    showViewsSelector: {
      type: Boolean,
      default: false,
    },
    showCreateView: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    templatesLoading: {
      type: Boolean,
      default: false,
    },
    showOnboarding: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();
    const snackBar = useSnackBar();
    const { debounce } = useDebounce();

    const viewSvc = inject(VIEWS_SERVICE);
    const { props: viewProps } = useViewParamsProps(viewSvc.currentView);
    const isFeed = computed(() => viewSvc.currentView.value.viewType === viewType.feed);
    const defaultPropsSvc = useUpdateDefaultProps({ isFeed });

    const updatesSvc = useUpdates();
    const updateTemplatesSvc = useUpdateTemplates();

    const updateDirty = ref(false);
    const handleRouteChange = (to, from, next) => {
      if (updateDirty.value === false) {
        next();
        return;
      }

      const showPrompt = (next) => {
        $confirm({
          title: t('general.discardEditPrompt'),
          okText: t('general.discardChanges'),
          cancelText: t('general.close'),
          okType: 'warning',
          maskClosable: true,
          onOk() {
            updateDirty.value = false;
            next();
          },
        });
      };

      showPrompt(next);
    };
    onBeforeRouteLeave(handleRouteChange);
    onBeforeRouteUpdate(handleRouteChange);

    const { fetchSchedules } = useUpdateSchedules();

    const { loggedInUserAccount } = useLoggedInUserAccount();
    const accountSettingsSvc = useAccountSettings();

    const onboardingMarker = useOnboardingMarker(loggedInUserAccount, accountSettingsSvc);

    const viewFilterObject = computed(() => {
      const handler = new UpdatesFilterHandler();
      const filterObject = handler.Translate(viewSvc.currentView.value.params.filter);
      filterObject.filterTree = combine(AND, [filterObject.filterTree, updatesSvc.updateTypesFilterObject([updateType.checkin]).filterTree]);
      return filterObject;
    });

    const page = ref(1);
    const itemsPerPage = ref(20);
    const totalAmount = ref(0);

    const retrieveUpdates = () => updatesSvc.fetchUpdates({
      filterObjects: [
        viewFilterObject.value,
        updatesSvc.updateTypesFilterObject([updateType.checkin]),
      ],
      order: viewSvc.currentView.value.params.order,
      pagination: {
        page: page.value,
        itemsPerPage: itemsPerPage.value,
      },
    }).then((data) => {
      totalAmount.value = data.count;
    });

    const firstLoadingSvc = useFirstLoading({ currentView: viewSvc.currentView });
    const loadFirst = () => {
      itemsPerPage.value = 20;
      firstLoadingSvc.startLoading();
      retrieveUpdates().catch(logCatch(() => {
        snackBar.error();
      })).finally(() => {
        firstLoadingSvc.finishLoading();
      });
    };

    loadFirst();

    const propertySvc = useUpdateProperty();
    const userSvc = useUsers();
    const { sort } = useUpdatesSorting(propertySvc.properties, userSvc);

    const filteredUpdates = computed(() => {
      const scopeTree = viewSvc.currentView.value.params.filter;
      const res = updatesSvc.updates.value.filter((entity) => isInFilter({ isFilterMode: true })({ entity, scopeTree }) && entity.type === updateType.checkin);

      let order = viewSvc.currentView.value.params.order;
      if (order === undefined) {
        order = [];
      }

      return sort(res, order).slice(0, itemsPerPage.value);
    });

    return {
      debounce,

      fetchSchedules,

      defaultProps: defaultPropsSvc.defaultProps,
      currentView: viewSvc.currentView,
      firstLoading: firstLoadingSvc.loading,
      viewProps,

      updates: updatesSvc.updates,
      updatesLoading: updatesSvc.fetchUpdatesLoading,

      fetchUpdateTemplates: updateTemplatesSvc.fetchUpdateTemplates,
      localTemplatesLoading: updateTemplatesSvc.fetchUpdateTemplatesLoading,
      updateDirty,

      isDone: onboardingMarker.isDone,

      page,
      itemsPerPage,
      totalAmount,
      viewFilterObject,
      filteredUpdates,

      retrieveUpdates,
      isFeed,
      loadFirst,
    };
  },
  components: {
    UpdateDivider,
    UpdatesListHeader,
    UpdateFeedItem,
    UpdateEditor,
    UpdateListItem,
    CheckinOnboardingModal,
    ProductTour,
    AfterCheckinTourModal,
  },
  data() {
    return {
      loadMoreLoading: false,
      showAfterProductTourModal: false,
      showSingleUpdate: false,
    };
  },
  computed: {
    headerSlots() {
      return [{ name: SLOTS.FILTER }, { name: SLOTS.SORTER }, { name: SLOTS.PROPERTIES }, { name: SLOTS.CREATE }];
    },
    anyTemplatesLoading() {
      return this.templatesLoading || this.localTemplatesLoading;
    },
    showTour() {
      return !this.listLoading && typeof this.$route.query.showCheckinTour !== 'undefined' && JSON.parse(this.$route.query.showCheckinTour) === true;
    },
    productTourSteps() {
      return [
        {
          highlightedElementClass: 'update-feed-item',
          heading: this.$t('productTour.checkins.step1.heading'),
          content: this.$t('productTour.checkins.step1.content'),
          placement: 'leftCenter',
        },
        {
          highlightedElement: 'create-update-btn',
          heading: this.$t('productTour.checkins.step2.heading'),
          content: this.$t('productTour.checkins.step2.content'),
          placement: 'bottomRight',
        },
        {
          highlightedElement: routeName.updateSchedules,
          heading: this.$t('productTour.checkins.step3.heading'),
          content: this.$t('productTour.checkins.step3.content'),
          placement: 'rightCenter',
        },
      ];
    },
    showCreatePage() {
      return typeof this.$route.query.create !== 'undefined';
    },
    listLoading() {
      return this.updatesLoading && !this.loadMoreLoading;
    },
    order() {
      return this.currentView.params.order;
    },
    singleUpdate() {
      if (typeof this.$route.query.updateId === 'undefined') {
        return null;
      }

      const u = this.updates.filter((u) => u.uid === parseInt(this.$route.query.updateId, 10));
      if (u.length === 0) {
        return null;
      }
      return u[0];
    },
    showOnboardingModal() {
      if (!this.showOnboarding) {
        return false;
      }

      return !this.isDone(onboardingSteps.checkInTour);
    },
  },
  methods: {
    isCurrentWeek(date) {
      return DateTime.local().diff(DateTime.fromISO(date)).as('week') < 1;
    },
    finishProductTour() {
      this.$router.push({ query: { ...this.$route.query, showCheckinTour: false } });
      this.showAfterProductTourModal = true;
    },
    closeTour() {
      this.$router.push({ query: { ...this.$route.query, showCheckinTour: false } });
    },
    loadMore() {
      if (this.totalAmount === 0) {
        return;
      }

      if (this.totalAmount <= this.itemsPerPage * this.page) {
        return;
      }

      this.itemsPerPage += 20;
      this.loadMoreLoading = true;
      this.retrieveUpdates().finally(() => {
        this.loadMoreLoading = false;
      });
    },
    openCreateModal() {
      this.$router.push({ query: { ...this.$route.query, create: '' } });
    },
    closeCreateModal() {
      const query = { ...this.$route.query };
      delete query.create;
      delete query.templateId;
      delete query.notificationId;
      this.$router.push({ query });
    },
    handleCreated() {
      this.updateDirty = false;
      const query = { ...this.$route.query };
      delete query.create;
      this.$router.push({ query });
      this.$showSnackbar({ color: 'success', message: this.$t('success.created') });
    },
  },
  watch: {
    order(val, oldVal) {
      if (JSON.stringify(val) === JSON.stringify(oldVal)) {
        return;
      }
      this.debounce(this.loadFirst, 700);
    },
    viewFilterObject: {
      handler(val, oldVal) {
        if (JSON.stringify(val) === JSON.stringify(oldVal)) {
          return;
        }
        this.debounce(this.loadFirst, 700);
      },
      deep: true,
    },
  },
  created() {
    if (typeof this.$route.query.updateId !== 'undefined') {
      this.showSingleUpdate = true;
    }

    this.fetchUpdateTemplates();
    this.fetchSchedules();
  },
  mixins: [updateDivider],
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .updates-explorer {
    min-width: 300px;

    ._header {
      position: sticky;
      left: 0;
      top: 0;
      background-color: white;
      z-index: 2;
    }

    ._list {
      padding-top: .6rem;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 5rem;

      ._spinner {
        position: absolute;
        top: 2rem;
        left: 46%;
      }

      ._inner {
        width: 100%;

        &.-feed {
          max-width: 600px;

          ._sticky-container {
            ._date-indicator {
              position: sticky;
              top: 4rem;
              z-index: 1;
              padding: .1rem 0;
              margin: 6rem 0;

              @media (max-width: $screen-size-md) {
                top: 6rem;
              }
            }

            ._divider {
              position: absolute;
              top: -.8rem;
              left: 0;
              width: 100%;
            }
          }
        }

        ._add {
          width: 100%;
        }
      }

      ._empty {
        margin: .8rem 0 .8rem .6rem;
      }

      ._item {
        margin: 2rem 0;
      }

      &.-bordered {
        ._item {
          margin: 0;
        }
      }
    }

    ._pagination {
      margin: 3rem 0;
    }
  }
</style>
