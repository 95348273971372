<template>
  <m-context-menu
    ref="contextMenu"
    class="grid-pages-context-menu"
    @hide="reset"
  >
    <m-card
      v-if="showMenu === 'menu'"
      list
      no-padding
    >
      <m-card-item
        icon="delete"
        :tooltip="deleteTooltip"
        :disabled="editableItems.length === 0"
        :loading="deleteLoading"
        @click="deleteItems"
      >
        {{ $t('general.delete') }}
        <template
          v-if="editableItems.length > 0"
          #right
        >
          <div
            v-if="cannotEditAllItems"
            class="_right"
          >
            <m-tooltip>
              <span>
                <m-icon
                  type="warning"
                  class="_icon"
                />
              </span>
              <template #title>
                {{ $t('gridPagesContextMenu.deleteHint') }}
              </template>
            </m-tooltip>
          </div>
        </template>
      </m-card-item>
      <m-card-item
        v-if="canCreate"
        icon="copy"
        :tooltip="duplicateTooltip"
        :disabled="cannotDuplicateAllItems"
        :loading="duplicateLoading"
        @click="duplicate"
      >
        {{ $t('general.duplicate') }}
      </m-card-item>
      <m-card-item
        icon="lock"
        :disabled="canChangeAccessItems.length === 0"
        :tooltip="accessTooltip"
        @click="changeAccess"
      >
        {{ $t('gridPagesContextMenu.changeAccess') }}
        <template
          v-if="canChangeAccessItems.length > 0"
          #right
        >
          <div
            v-if="!hasPublicAllowance && cannotMakePublicAllItems && privateItems.length > 0"
            class="_right"
          >
            <m-tooltip>
              <span>
                <m-icon
                  type="warning"
                  class="_icon"
                />
              </span>
              <template #title>
                {{ $t('gridPagesContextMenu.makePublicHint') }}
              </template>
            </m-tooltip>
          </div>
          <div
            v-else-if="cannotChangeAccessAllItems && fullAccessItems.length > 0"
            class="_right"
          >
            <m-tooltip>
              <span>
                <m-icon
                  type="warning"
                  class="_icon"
                />
              </span>
              <template #title>
                {{ $t('gridPagesContextMenu.changeAccessHint') }}
              </template>
            </m-tooltip>
          </div>
        </template>
      </m-card-item>
    </m-card>
    <m-card
      v-else-if="showMenu === 'access-editor'"
      no-padding
    >
      <access-editor
        :items="toEdit"
        :update-entities-fn="updateGridPagesAccessPolicy"
        :success-message="$t('success.updated')"
        @close="hideMenu"
        @edited="$emit('access-edited')"
      />
    </m-card>
  </m-context-menu>
</template>

<script>
import AccessEditor from '@/components/access-policy/AccessEditor.vue';
import useAccess from '@/composables/access/access';
import useGridPage from '@/composables/grid-page/grid-page';
import useSnackBar from '@/composables/snackbar';
import { accessGroupFlag, accessPolicyType } from 'shared/constants.json';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'GridPagesContextMenu',
  props: {
    ids: {
      type: Array,
      required: true,
    },
    hasPublicAllowance: {
      type: Boolean,
      default: false,
    },
    privateAllowanceAvailable: {
      type: Number,
      default: -1,
    },
    publicAllowanceAvailable: {
      type: Number,
      default: -1,
    },
  },
  components: { AccessEditor },
  data() {
    return {
      deleteLoading: false,
      duplicateLoading: false,
      showMenu: 'menu',
      toEdit: [],
      accessPolicyType,
    };
  },
  emits: ['access-edited', 'pages-deleted'],
  setup() {
    const snackbar = useSnackBar();
    const { userHasRight } = useAccess();
    const { gridPages, updateGridPagesAccessPolicy, deleteGridPages, duplicateGridPages } = useGridPage();
    return {
      snackbar,
      userHasRight,
      gridPages,
      updateGridPagesAccessPolicy,
      deleteGridPages,
      duplicateGridPages,
    };
  },
  computed: {
    items() {
      return this.gridPages.filter((e) => this.ids.includes(e.uid));
    },
    privateItems() {
      return this.items.filter((e) => e.isPublic === false);
    },
    publicItems() {
      return this.items.filter((e) => e.isPublic === true);
    },
    editableItems() {
      return this.items.filter((e) => [accessPolicyType.write, accessPolicyType.full].includes(e.accessRight));
    },
    cannotEditAllItems() {
      return this.editableItems.length !== this.items.length;
    },
    deleteTooltip() {
      if (this.editableItems.length > 0) {
        return '';
      }
      return this.$t('gridPagesContextMenu.cannotDeleteMultiple');
    },
    canDuplicateItems() {
      return this.items;
    },
    cannotDuplicateAllItems() {
      return ((this.privateAllowanceAvailable >= 0 && this.privateItems.length > this.privateAllowanceAvailable)
          || (this.publicAllowanceAvailable >= 0 && this.publicItems.length > this.publicAllowanceAvailable));
    },
    duplicateTooltip() {
      if (!this.cannotDuplicateAllItems) {
        return '';
      }
      return this.$t('gridPagesContextMenu.cannotDuplicateAll');
    },
    fullAccessItems() {
      return this.items.filter((e) => e.accessRight === accessPolicyType.full);
    },
    canChangeAccessItems() {
      if (!this.hasPublicAllowance) {
        return this.fullAccessItems.filter((e) => e.isPublic === true);
      }
      return this.fullAccessItems;
    },
    cannotMakePublicAllItems() {
      return this.privateItems.length !== this.items.length;
    },
    cannotChangeAccessAllItems() {
      return this.fullAccessItems.length !== this.items.length;
    },
    accessTooltip() {
      if (this.canChangeAccessItems.length > 0) {
        return '';
      }
      if (!this.hasPublicAllowance && this.privateItems.length > 0) {
        return this.$t('gridPagesContextMenu.cannotMakePublicMultiple');
      }
      if (this.fullAccessItems.length === 0) {
        return this.$t('gridPagesContextMenu.cannotEditMultiple');
      }
      return '';
    },
    canCreate() {
      return this.userHasRight([accessGroupFlag.createDashboard]);
    },
  },
  methods: {
    hideMenu() {
      this.$refs.contextMenu.hide();
    },
    show(event) {
      this.$refs.contextMenu.show(event);
    },
    reset() {
      this.showMenu = 'menu';
    },
    deleteItems() {
      this.hideMenu();
      const deleteMethod = () => {
        this.deleteLoading = true;
        this.deleteGridPages(this.editableItems).then(() => {
          this.$emit('pages-deleted');
          this.snackbar.success(this.$t('success.deleted'));
        }).catch(logCatch(() => {
          this.snackbar.error();
        })).finally(() => { this.deleteLoading = false; });
      };

      this.$confirm({
        title: this.$t('general.deletePrompt'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteMethod();
        },
      });
    },
    duplicate() {
      this.duplicateLoading = true;
      this.duplicateGridPages(this.canDuplicateItems).then(() => {
        this.duplicateLoading = false;
        this.hideMenu();
      });
    },
    changeAccess() {
      this.toEdit = this.canChangeAccessItems;
      this.showMenu = 'access-editor';
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>

</style>
