<template>
  <div class="update-list-item">
    <list-item
      class="_item"
      :entity="entity"
      :props="visibleProps"
      :tiny="tiny"
      @select="showModal = true"
    >
      <template #title>
        <user-display
          class="_user"
          :user="creator"
          large
        >
          <template
            v-if="showCreatedAtProp"
            #subName
          >
            <div class="_date">
              {{ formatDate(update.createdAt) }}
              <template v-if="update.createdAt !== update.modifiedAt">
                ({{ $t('updateFeedItem.edited') }})
              </template>
            </div>
          </template>
        </user-display>
      </template>
      <template #beforeProps>
        <item-wrapper
          v-if="titleProp !== undefined"
          class="_prop-item"
          :tooltip="titleProp.label"
          show-tooltip
        >
          <m-tag
            :icon="buildIconFromEntity(update)"
            :title="update.title"
            small
            :style="{ marginRight: '.4rem' }"
          />
        </item-wrapper>
      </template>
    </list-item>
  </div>
  <m-dialog
    :value="showModal"
    :max-width="$modalSizes.xl"
    hide-footer
    no-padding
    keep-height
    hide-header
    top="7rem"
    @close="handleClose"
  >
    <update-editor
      :update="update"
      disabled
      :modal="true"
      @saved="updateDirty = false"
      @is-dirty="updateDirty = true"
    />
  </m-dialog>
</template>

<script>
import ItemWrapper from '@/components/list/ItemWrapper.vue';
import ListItem from '@/components/list/ListItem.vue';
import UpdateEditor from '@/components/updates/UpdateEditor.vue';
import UserDisplay from 'shared/components/UserDisplay.vue';
import useProperties from '@/composables/property/property';
import useUsers from '@/composables/user/users';
import { CREATED_AT } from 'shared/api/query/constants';
import { DateTime } from 'luxon';
import { buildIconFromEntity } from 'shared/lib/icon';
import { propertyType } from 'shared/constants.json';

export default {
  name: 'UpdateListItem',
  props: {
    update: {
      type: Object,
      required: true,
    },
    props: {
      type: Array,
      required: true,
    },
    tiny: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { mooncampBotUser } = useUsers();
    const { updateProperties } = useProperties();
    return { mooncampBotUser, updateProperties };
  },
  components: { ListItem, ItemWrapper, UpdateEditor, UserDisplay },
  data() {
    return {
      propertyType,
      showModal: false,
      updateDirty: false,
    };
  },
  computed: {
    entity() {
      if (this.update.title !== '') {
        return this.update;
      }
      return {
        ...this.update,
        title: DateTime.fromISO(this.update.createdAt).toLocaleString(DateTime.DATETIME_MED),
      };
    },
    creator() {
      if (this.update.generated) {
        return this.mooncampBotUser;
      }

      return this.update.creator;
    },
    visibleProps() {
      return this.props.filter((p) => !p.hideInProps && p.show && !p.isTitle
      && !(p.isDirect === true && p.property.edgeName === CREATED_AT));
    },
    titleProp() {
      const titleProp = this.props.find((p) => p.isTitle);
      if (titleProp !== undefined && titleProp.show && this.update.title !== '') {
        return titleProp;
      }
      return undefined;
    },
    showCreatedAtProp() {
      const createdAtProp = this.props.find((p) => p.isDirect === true && p.property.edgeName === CREATED_AT);
      if (createdAtProp !== undefined) {
        return createdAtProp.show;
      }
      return false;
    },
  },
  methods: {
    buildIconFromEntity,
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);
    },
    handleClose() {
      const close = () => {
        this.showModal = false;
        this.updateDirty = false;
      };

      if (this.updateDirty) {
        this.$confirm({
          title: this.$t('general.discardEditPrompt'),
          okText: this.$t('general.discardChanges'),
          cancelText: this.$t('general.close'),
          okType: 'warning',
          maskClosable: true,
          onOk() {
            close();
          },
        });
        return;
      }

      close();
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .update-list-item {
    ._user {
      min-width: 30rem;
      line-height: 1.1;
      font-weight: $font-weight-semibold;
      margin-right: auto;

      ._date {
        font-size: $font-size-2;
        color: $font-color-secondary;
        font-weight: normal;
      }
    }
  }
</style>
